import { ButtonGroup, TextField, Button, Stack, Box, List, ListItem, FormControl, InputLabel,  MenuItem } from "@mui/material";
import React, { useState } from "react";
import { create, all } from "mathjs";
import Select,  { SelectChangeEvent } from '@mui/material/Select';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

type Props = {
  lightColor: string;
  mediumColor: string; 
  darkColor: string; 
  derivativeClick: any; 
}


export const Derivative = ({lightColor, mediumColor, darkColor, derivativeClick}:Props) => {
    const math = create(all);

  const [input, setInput] = useState("");
  const [answer, setAnswer] = useState("");

  const [list, setList] = useState<string[]>([]);
  const [selectValue, setSelectValue] = React.useState(""); //used for button group: select component
  const [openHelp, setOpenHelp] = React.useState(false);
    const [hideCalculator, setHideCalculator] = useState(false)

    //Used for button group: select component 
  const handleChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value as string);
  };


  const handleClick = () => {
    try {
      if (input === "" || input === undefined || input === null) {
        throw new Error("missing");
      }
      const ans = input + "=" + math.derivative(input,'x').toString();
      const newList = [...list, ans];
      setAnswer(ans);
      setList(newList);
      setInput("");
  }
  catch (err: any) {
    console.log(err);
    switch (err.message) {
      case "missing":
        alert("Input is empty. Please enter an expression.");
        break;
      // case `Undefined symbol ${input}`:
      //   alert("Variables cannot be defined here.");
      //   break;
      // default:
      //   break;
    }
  }
}

  function ListItem(props: {
    value:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
  }) {
    return <li>{props.value}</li>;
  }
  const handleHelpClick = () => {
    setOpenHelp((prev) => !prev);
  };

  const handleClickHelpAway = () => {
    setOpenHelp(false);
  };  

    const handleHideCalc = () => {
    setHideCalculator(true)
    derivativeClick(false)
  }
    return (
      <div>
        {!hideCalculator && (
        <Box sx = {{backgroundColor: 'rgba(0,0,0,.1)' ,boxShadow: 8, width: '90%', borderRadius: 2,  ml :'30px', mb: '10px'}}>
          <IconButton sx = {{color: 'white'}} onClick = {handleHideCalc}>
            <CloseIcon/>
          </IconButton>

        <div>
        <Box maxWidth="xs" sx={{ ml: 7, fontSize: 25 }}>
          <List>
            {list.map((lists) => (
              <ListItem key={lists.toString()} value={lists} />
            ))}
          </List>
        </Box>

        <Stack spacing={1} direction="row" sx = {{ml: '10px', mr: '11px'}}>

          <TextField
            id="equation"
            variant="outlined"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            sx = {{backgroundColor: 'white', borderRadius: 1, "& label.Mui-focused": {
              color: "#1b4332"},   "& .MuiFilledInput-underline:after": {borderBottomColor: "#1b4332"},width: '68%'}}
          />
          <Button onClick={handleClick} variant="contained"  sx = {{width: '30%',backgroundColor: mediumColor, ":hover": {
              bgcolor: darkColor
            }}}>
            Derive
          </Button>

          <ClickAwayListener onClickAway={handleClickHelpAway}>
            <Box sx={{ position: 'relative' }}>
              <IconButton type="button" sx = {{color: 'white'}} onClick={handleHelpClick}>
              <HelpOutlineIcon></HelpOutlineIcon>
              </IconButton>
              {openHelp ? (
                <Box>
                  Solves derivatives
                </Box>
              ) : null}
            </Box>
         </ClickAwayListener> 

        </Stack>

        <Stack direction = 'column' sx = {{ml :'10px',mr: '10px' ,mt: '5px'}}>

          <ButtonGroup variant = "text" color = 'success' sx = {{backgroundColor:'white', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
            <Button onClick = {() => setInput(input + "π")} sx = {{width: '33%',color: darkColor,borderColor: '#40916c' ,":hover": { bgcolor: lightColor } }}>
                𝝅
            </Button>

            <Button onClick = {() => setInput(input + "√()")}sx = {{width: '33%' ,color: darkColor,borderColor: '#40916c' ,":hover": { bgcolor: lightColor } }} > 
                √
            </Button>
              
            <Button onClick = {() => setInput(input + "^")} sx = {{width: '33%', color: darkColor,borderColor: '#40916c' ,":hover": { bgcolor: lightColor } }} >
                ^
            </Button>
          </ButtonGroup>

          <ButtonGroup variant = "text" color = 'success' sx = {{backgroundColor:'white', borderRadius: 0}}>
            <Button onClick = {() => setInput(input + "cos()")}  sx = {{width: '33%', color: darkColor,borderColor: '#40916c' ,":hover": { bgcolor: lightColor } }}  >
              cos
            </Button>

            <Button onClick = {() => setInput(input + "sin()")} sx = {{width: '33%' , color: darkColor,borderColor: '#40916c' ,":hover": { bgcolor: lightColor } }} >
              sin
            </Button>

            <Button onClick = {() => setInput(input + "tan()")} sx = {{width: '33%' , color: darkColor,borderColor: '#40916c' ,":hover": { bgcolor: lightColor } }}>
              tan
            </Button>
          </ButtonGroup>

          <ButtonGroup variant="text" color = 'success' sx = {{mb: '10px',backgroundColor:'white', borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <Button onClick = {() => setInput(input + "arccos()")} sx = {{width: '33%', color: darkColor,borderColor: '#40916c' ,":hover": { bgcolor: lightColor } }}>
              cos <sup>-1</sup>
            </Button>

            <Button onClick = {() => setInput(input + "arcsin()")} sx = {{width: '33%', color: darkColor,borderColor: '#40916c' ,":hover": { bgcolor: lightColor } }}>
                sin<sup>-1</sup>
            </Button>

            <Button onClick = {() => setInput(input + "arctan()")} sx = {{width: '33%',  color: darkColor,borderColor: '#40916c' ,":hover": { bgcolor: lightColor } }}>
              tan<sup>-1</sup>
            </Button>
          </ButtonGroup>
      </Stack>
      </div>
      </Box>
      )}
      </div>

    );
}