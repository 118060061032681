import Box from '@mui/material/Box';
import NavBar from './components/navBar';
import { Outlet } from 'react-router-dom';

function Layout(){
  return(
    <Box>
        <NavBar/>
        <Outlet/>
          
    </Box>
  )
}
export default Layout; 