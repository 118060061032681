import React, {useState}from "react";
import { Box, Button, Stack, IconButton, Typography, Grid } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


import { Evaluate } from "./Evaluate";
import { Simplify } from "./Simplify";
import { Derivative } from "./Derive";
type Props = {
  lightColor: string;
  mediumColor: string; 
  darkColor: string; 
}

function DisplayCalculator ({lightColor, mediumColor, darkColor}: Props){
  const [showSimplify, setShowSimplify] = useState(false);
  const [showDerivative, setShowDerivative] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openCalcMenu = Boolean(anchorEl);

  const handleCalcMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleCalcMenuClose = () => {
    setAnchorEl(null);
  };
  
  const derivativeClick = () => {
    setShowDerivative(deriveClick => !deriveClick);
    setAnchorEl(null);
  }
  const simplifyClick = () => {
    setShowSimplify(newState => !newState);
    setAnchorEl(null);
  }
  return(
    <div>

        <Evaluate lightColor={lightColor} mediumColor= {mediumColor} darkColor={darkColor}/>

        <Button onClick = {handleCalcMenuClick} sx = {{backgroundColor : '#1F1F1F', color: 'white', borderRadius: 2,borderTopLeftRadius: 0,borderTopRightRadius: 0, ml :'30px'}} >
          <KeyboardArrowDownIcon/>
        </Button>

        <Menu 
        anchorEl={anchorEl} open={openCalcMenu} onClose={handleCalcMenuClose}>
          <MenuItem  onClick = {simplifyClick}> Simplify </MenuItem>
          <MenuItem onClick = {derivativeClick}> Derivative </MenuItem>
          
        </Menu>
 

        <div> 
          {showSimplify && (
            <div>
              <Simplify lightColor={lightColor} mediumColor= {mediumColor} darkColor={darkColor} simplifyClick={simplifyClick} ></Simplify>
            </div>
          )}

          {showSimplify &&<Box/>}
        </div>
  

      <br></br>
      {showDerivative &&(
        <div>
          <Derivative lightColor={lightColor} mediumColor= {mediumColor} darkColor={darkColor} derivativeClick={derivativeClick}></Derivative>
        </div>
      )}
  

    </div>

  )
}
export default DisplayCalculator; 