import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useState } from 'react';
import { Link } from "react-router-dom";

import ToDoList from './tools/todolist';
import NavBar from './navBar'
import React from 'react'
import TimerTool from './tools/timer/timerTool';
import DisplayCalculator from './tools/mathComponents/displayCalculator';
import SoundBar from './tools/audio/soundBar';

type Props = {
  url: string; 
  lightColor: string;
  mediumColor: string;
  darkColor: string; 
  gradient: string; 
}

let vidHeight = '700px'

function AmbianceLayout({url, lightColor, mediumColor, darkColor, gradient} : Props){

  return(
    <Box  sx = {{background: gradient, maxHeight: '100%', minHeight: '909px'}}>
      <Grid container >
        <Grid xs = {12} md = {7} sx = {{ml: '30px', mt: '10px'}}>
          <iframe width = "100%"  height = {vidHeight}  src={url} title="YouTube video" allowFullScreen></iframe>
        </Grid>

        <Grid xs = {12} md = {4} >
          <Stack direction = 'column' spacing = {2} justifyContent='center' alignItems = 'center'>
            <SoundBar/>

            <TimerTool />
          </Stack>
        </Grid>
    
        <Grid xs = {12} md = {5} >
          <DisplayCalculator lightColor={lightColor} mediumColor= {mediumColor} darkColor={darkColor}/>
        </Grid>

        <Grid xs = {12} md = {3}>
          <ToDoList lightColor={lightColor} mediumColor= {mediumColor} darkColor={darkColor} />

        </Grid>


      </Grid>
        {/* {displayVideo ? <p>Hello world</p> : <p>Video is not displayed</p>} */}
 
  </Box>



 
  )
}
export default AmbianceLayout; 