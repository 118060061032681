// components/PlayerControls.tsx

import { useRef, useState, useMemo, useEffect } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';


type Props = {
  playerRef: any;
  playing: boolean;
  volume: number;
  muted: boolean;

  handlePlay: () => void;
  toggleMute: () => void;
  handlePause: () => void;
  handleVolumeChange: (newVolume: number) => void;
};
const PlayerControls = ({
  playerRef,
  playing,
  volume,
  muted,
  handlePlay,
  handlePause,
  handleVolumeChange,
  toggleMute,
}: Props) => {
  useEffect(() => {
    playPauseButtonRef.current?.focus();
  }, []);
  const [played, setPlayed] = useState<number>(0);
  const [seeking, setSeeking] = useState<boolean>(false);
  const playPauseButtonRef = useRef<HTMLButtonElement>(null);

  const togglePlayAndPause = () => {
    if (playing) {
      handlePause();
    } else {
      handlePlay();
    }
  };


  const handleChangeInVolume =  (e: React.ChangeEvent<HTMLInputElement>) => {
    handleVolumeChange(Number(e.target.value));
   };

  return (
      <Box >
        <Stack direction = "row">
            {/* mute button */}
            <IconButton onClick={toggleMute} sx = {{color: 'white'}} >
              {muted ? <VolumeMuteIcon /> : <VolumeUpIcon />}
            </IconButton>

           {/* volume control */}
          <input
            type="range"
            className="focus:outline focus:outline-cyan-500 w-[50%] h-2 rounded-lg  bg-slate-400 accent-gray-900"
            min={0}
            max={1}
            step={0.1}
            value={volume}
            onChange={handleChangeInVolume}
          />

     
            {/* play/pause button */}
            <IconButton ref={playPauseButtonRef} onClick={togglePlayAndPause} sx = {{color: 'white'}}>
            {playing ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          
        </Stack>
      </Box>
    
  );
};
export default PlayerControls; 