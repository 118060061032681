import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Link } from "react-router-dom";
function HomeAmbiance(){
  return(
    <Box sx = {{mt: '10px'}}>
      <Grid container direction = "row" justifyContent= "center" alignItems="center" spacing={2} sx = {{mb: '10px'}} >

        <Grid item xs = {2} md = {2}>
          <Typography variant='h6'>
            Choose your ambiance: 
          </Typography>
        </Grid>

        <Grid item xs = {2} md = {2}>
          <Button variant = "contained">
            <Link to ="dark-academia" style={{ textDecoration: 'none' }}>
              <Typography color={'white'}>
              Dark Academia
              </Typography>
            </Link>
          </Button>
        </Grid>

        <Grid item xs = {2} md = {2}>
          <Button variant = "contained">
            <Link to ="lofi-hip-hop" style={{ textDecoration: 'none' }}>
            <Typography color={'white'}>
              Lofi Hip-Hop
              </Typography>
            </Link>
          </Button>
        </Grid>
        
        <Grid item xs = {2} md = {2}>
          <Button variant = "contained">
            <Link to ="coffee-shop" style={{ textDecoration: 'none' }}>
            <Typography color={'white'}>
              Coffee Shop
              </Typography>
            </Link>
          </Button>
        </Grid>

        <Grid item xs = {2} md = {2}>
          <Button variant = "contained">
            <Link to ="create-your-own" style={{ textDecoration: 'none' }}>
            <Typography color={'white'}>
              Create Your Own
            </Typography>
            </Link>
          </Button>
        </Grid>

      </Grid>
      <Divider/>
    </Box>

  )
}
export default HomeAmbiance;