import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import StudianceLogo from '../Studiance logo.jpg'
import { Button, Stack, Typography } from '@mui/material';

import { Link } from "react-router-dom";
function Home(){
  return(
    <Box>
      <Grid container direction={'row'} justifyContent="center" alignItems="center">
        <Grid xs = {4} md = {4}  >
          <Box  sx ={{height: '100vh', mt: '20px', ml: '50px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="744" height="842" viewBox="0 0 744 842">
              <defs>
                <clipPath id="clip-studiance_-_Home_log_in_sign_up_1">
                  <rect width="744" height="842"/>
                </clipPath>
              </defs>
               <g id="studiance_-_Home_log_in_sign_up_1" data-name="studiance - Home (log in/sign up) – 1" clip-path="url(#clip-studiance_-_Home_log_in_sign_up_1)">
              <rect width="744" height="842" fill="#fff"/>
              <g id="Group_14" data-name="Group 14" transform="matrix(0.899, -0.438, 0.438, 0.899, -379.785, 197.116)">
              <path id="Path_24" data-name="Path 24" d="M121.482,127.862v278.93Q247.225,459.31,372.968,511.822q131.7-52.509,263.379-105.031V127.862L372.968,231.167Q247.225,179.512,121.482,127.862Z" transform="translate(74.065 389.118)" fill="#3554fc"/>
              <path id="Path_25" data-name="Path 25" d="M426.644,67.812,163.761,169.57q-1.823,138.564-3.632,277.127,131.428-50.022,262.863-100.044Q547.18,399.213,671.373,451.772,673.186,313.208,675,174.639,550.822,121.231,426.644,67.812Z" transform="translate(290.561 97.028)" fill="#3554fc"/>
              </g>
              </g>
            </svg>
          </Box>
        </Grid>

        <Grid xs = {6} md = {6} >
   
          <Typography variant='h1' color={'black'}>
            Studiance
          </Typography>
          <Stack justifyContent="center" alignItems="center">
          <Typography variant='h4' color={'black'}>
            Design your workspace for productivity and inspiration.
          </Typography>
          <Link to ="/lofi-hip-hop">
          <Button variant='outlined'  sx = {{mt: '5px', textTransform: 'capitalize'}}> launch workspace </Button>
          </Link>
          </Stack>
          
        </Grid>
      </Grid>
    </Box>

  )
}
export default Home; 