import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {useState} from 'react'

import AudioPlayer from '././indivPlayers/lofiPlayer';
import AudioPlayerClassical from './indivPlayers/classicalPlayer';
import AudioPlayerRain from './indivPlayers/rainPlayer'
import JazzPlayer from './indivPlayers/jazzPlayer';
import FunPlayer from './indivPlayers/funPlayer';
// const audio = {
//   url: lofiAudio};

type Props = {
  createAudioBar?: boolean;
}
function SoundBar({createAudioBar}: Props){
  const [open, setOpen] = useState(true)

  return(
    <Box sx = {{backgroundColor: 'rgba(0,0,0,.1)' ,boxShadow: 5,  width: '90%', borderRadius: 2, mt: '10px'}}>

      {!open && (
          <Box>
            <Grid container direction = 'row'> 
              <Grid xs = {4.6} md = {4.6}>
                <IconButton sx = {{color:'white'}} onClick = {()=> {setOpen(current => !current)}}>
                  <ExpandMoreIcon/>
                </IconButton>
              </Grid>

              <Grid xs = {5} md = {5}>
                <Typography variant = 'h6' color={'white'} alignItems = 'center'> <b>Testing 123</b></Typography>
              </Grid>
            </Grid>
          </Box>
        )}

      {open && (
      <div>
        <Grid container direction = 'row'> 
              <Grid xs = {4.6} md = {4.6}>
                <IconButton sx = {{color:'white'}} onClick = {()=> {setOpen(current => !current)}}>
                  <ExpandLessIcon/>
                </IconButton>
              </Grid>

              <Grid xs = {5} md = {5}>
                <Typography variant = 'h6' color={'white'} alignItems = 'center'> <b>Sound Bar</b></Typography>
              </Grid>
            </Grid>

        <Stack direction = "column" justifyContent='center' alignItems = 'center'>
          { /* https://blog.ismailobadimu.com/building-a-custom-audio-player-with-react-typescript */}
          <List>    
            <ListItem>
              <AudioPlayer/>
            </ListItem>
            <Divider sx = {{background: 'white'}}/>
              <ListItem>
                <AudioPlayerClassical/>
              </ListItem>
            <Divider sx = {{background: 'white'}}/>
              <ListItem>
                <AudioPlayerRain/>
              </ListItem>
            <Divider sx = {{background: 'white'}}/>
              <ListItem>
                <JazzPlayer/>
              </ListItem>
            <Divider sx = {{background: 'white'}}/>
              <ListItem>
                <FunPlayer/>
              </ListItem>
            

          </List>
          
        </Stack>
      </div>
      )}
    </Box>
  )
}
export default SoundBar; 