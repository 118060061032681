import BuildIcon from '@mui/icons-material/Build';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CalculateIcon from '@mui/icons-material/Calculate';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Stack from '@mui/material/Stack';
import TimerIcon from '@mui/icons-material/Timer';
import Typography from '@mui/material/Typography';

import { Link } from "react-router-dom";
import * as React from 'react';
import { Icon, IconButton, SvgIcon } from '@mui/material';
import { useState } from 'react';
import LofiHipHop from '../pages/ambianceOptions/lofi-hip-hop';


function NavBar() {
  const [anchorElAmb, setAnchorElAmb] = React.useState<null | HTMLElement>(null); //Ambiance button 
  const [anchorElTools, setAnchorElTools] = React.useState<null | HTMLElement>(null); //Tools button 
  const [displayVideo, setDisplayVideo] = useState<boolean>(false)


  //Ambiance menu open and close 
  const open = Boolean(anchorElAmb);
  const handleAmbianceClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAmb(event.currentTarget);
  };
  const handleAmbianceClose = () => {
    setAnchorElAmb(null);
  };

  //Tools menu open and close\  
  const openTools = Boolean(anchorElTools);
  const handleToolsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElTools(event.currentTarget);
  };
  const handleToolsClose = () => {
    setAnchorElTools(null);
  };
  return(
    <Box sx ={{backgroundColor: 'black', borderRadius: 0}}>
          <Stack alignItems= "center" direction='row' spacing = {5}>
            <Button sx = {{ml: '30px'}} >
              <Link to ="/" style={{ textDecoration: 'none' }}>
                
                <svg width="48" height="80" viewBox="0 0 98.241 149.901">
                  <g id="Group_14" data-name="Group 14" transform="matrix(0.899, -0.438, 0.438, 0.899, -162.788, 38.835)" >
                    <path id="Path_24" data-name="Path 24" d="M121.482,127.862v43.987l34.483,16.563,36.114-16.563V127.862l-36.114,16.291Z" transform="translate(0 -5.589)" fill="#fff"/>
                    <path id="Path_25" data-name="Path 25" d="M196.929,67.812l-36.3,16.151q-.252,21.992-.5,43.984l36.3-15.878,34.3,16.684q.25-21.992.5-43.985Z" transform="translate(-3.597 0)" fill="#fff"/>
                  </g>
                </svg>
              </Link>
            </Button>



              <Link to="/create-your-own" style={{ textDecoration: 'none' }} >
                <Button sx = {{color: 'white'}} >
                <Typography color={'white'}> Create </Typography>
                </Button>
              </Link>

              <Button onClick={handleAmbianceClick} sx = {{color: 'white'}} >
                <Typography color={'white'}> Choose a vibe </Typography>
              </Button>

              <Menu id="basic-menu" anchorEl={anchorElAmb} open={open} onClose={handleAmbianceClose} MenuListProps={{'aria-labelledby': 'basic-button',}}  anchorOrigin={{vertical: 'center',horizontal: 'right',}} >
              <Link to='/lofi-hip-hop' style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={handleAmbianceClose} sx ={{color:'black'}}>Lofi Hip-Hop</MenuItem>
                </Link>
                <Link to='/dark-academia' style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={handleAmbianceClose} sx ={{color:'black'}}>Dark Academia</MenuItem>
                </Link>
                <Link to='/coffee-shop' style={{ textDecoration: 'none' }} >
                  <MenuItem onClick={handleAmbianceClose} sx ={{color:'black'}}>Coffee Shop</MenuItem>
                </Link>
                <Link to='/create-your-own' style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={handleAmbianceClose} sx ={{color:'black'}}>Create Your Own</MenuItem>
                </Link>
              </Menu>

 
{/* 
              <Button onClick={handleToolsClick} sx = {{color: 'white'}} >
                  <Typography color={'white'}> Tools </Typography>
                </Button> */}
        
{/* 
              <Menu id="basic-menu" anchorEl={anchorElTools} open={openTools} onClose={handleToolsClose} MenuListProps={{'aria-labelledby': 'basic-button',}}  anchorOrigin={{vertical: 'center',horizontal: 'right',}} > */}
{/*          
              <MenuItem onClick={() => {
                handleToolsClose();
                setDisplayVideo(true);
                
                }} sx ={{color:'black'}}>

                  {/* <LofiHipHop displayVideo={displayVideo}/> */}

                {/* <ListItemIcon>
                  <OndemandVideoIcon sx = {{color:'black'}}/> 
                </ListItemIcon>

                <ListItemText>
                 YouTube Video Player
                </ListItemText>
              </MenuItem> */} 

              {/* <MenuItem onClick={handleToolsClose} sx ={{color:'black'}}>
                <ListItemIcon>
                  <MusicNoteIcon sx = {{color:'black'}}/>
                </ListItemIcon>

                <ListItemText>
                  Ambient Sounds
                </ListItemText>
              </MenuItem> */}

              {/* <MenuItem onClick={handleToolsClose} sx ={{color:'black'}}>
                <ListItemIcon>
                  <TimerIcon sx = {{color:'black'}}/>
                </ListItemIcon>

                <ListItemText>
                  Timer
                </ListItemText>
              </MenuItem> */}
{/* 
              <MenuItem onClick={handleToolsClose} sx ={{color:'black'}}>
                <ListItemIcon>
                  <CalculateIcon sx = {{color:'black'}}/>
                </ListItemIcon>
                
                <ListItemText>
                  Calculator
                </ListItemText>
              
              </MenuItem> */}
              {/* <MenuItem onClick={handleToolsClose} sx ={{color:'black'}}>
                <ListItemIcon>
                  <ListAltIcon sx = {{color:'black'}}/>
                </ListItemIcon>

                <ListItemText>
                  To-DoList
                </ListItemText>
              </MenuItem> */}
            {/* </Menu> */}
          </Stack>

          <Divider/>
    </Box>

  )
}
export default NavBar;



