import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import SoundBar from '../../components/tools/audio/soundBar';
import TimerTool from '../../components/tools/timer/timerTool';
import DisplayCalculator from '../../components/tools/mathComponents/displayCalculator';
import ToDoList from '../../components/tools/todolist';
import { useState,useRef } from 'react';
import ReactPlayer from 'react-player';
type Props = {
  lightColor: string;
  mediumColor: string;
  darkColor: string; 
  gradient: string; 
}
let vidHeight = '700px'

function CreateLayout({lightColor, mediumColor, darkColor, gradient} : Props){
  const customPlayerRef = useRef<ReactPlayer | null>(null);
  let [link, setLink] = useState("https://www.youtube.com/watch?v=4z1HZUvgMbw");
  return(
    <Box  sx = {{background: gradient, maxHeight: '100%', minHeight: '909px', mb: '20px'}}>
      <Grid container>
        <Grid xs = {8} md = {8} sx = {{ml: '30px', mt: '10px', mb: '10px'}}>
          <ReactPlayer url={link} ref={customPlayerRef} controls= {true} width = "100%"  height = {vidHeight}  title="YouTube video" allowFullScreen />
          <TextField label = "Enter Youtube video link" variant='outlined' value = {link} onChange={(e) => setLink(e.target.value)} sx = {{width: '87%'}} />
        </Grid>
       
        <Grid xs = {3} md = {3} >
          <Stack direction = 'column' spacing = {2} justifyContent='center' alignItems = 'center'>
            <SoundBar/>
            <TimerTool />
          </Stack>
        </Grid>

        <Grid xs = {5} md = {5} >
          <DisplayCalculator lightColor={lightColor} mediumColor= {mediumColor} darkColor={darkColor}/>
        </Grid>

        <Grid xs = {3} md = {3}>
          <ToDoList lightColor={lightColor} mediumColor= {mediumColor} darkColor={darkColor} />
        </Grid>

      </Grid>
  </Box>
  )}
export default CreateLayout; 

