import { Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import React, {useState} from 'react'
import StopIcon from '@mui/icons-material/Stop';
import Typography from '@mui/material/Typography';

import CountDownTimer from './countDownTimer';

const TimerTool = () => {  
  let [hrsInput, setHrsInput] = useState("");
  let [minsInput, setMinsInput] = useState("");
  let [startTimer, setTimer] = useState(false);
  const [open, setOpen] = useState(true)


  const startTimerClick = () => {
    setTimer(current => !current) //displays the timer and hides it when button clicks 
    // setResetTimer (current => !current)
    if (minsInput === ""){
      setMinsInput("0")
    }
    if (hrsInput === ""){
      setHrsInput("0")
    }
  }


  return(
    <Box sx = {{backgroundColor: 'rgba(0,0,0,.1)' ,boxShadow: 8, width: '90%', borderRadius: 2}}>
        {!open && (
          <Box>
            <Grid container direction = 'row'> 
              <Grid xs = {5} md = {5}>
                <IconButton sx = {{color:'white'}} onClick = {()=> {setOpen(current => !current)}}>
                  <ExpandMoreIcon/>
                </IconButton>
              </Grid>

              <Grid xs = {5} md = {5}>
                <Typography variant = 'h6' color={'white'} alignItems = 'center'> <b>Timer</b></Typography>
              </Grid>
            </Grid>
          </Box>
        )}
  
        {open && (
        <Box>
            <Grid container direction = 'row'> 
              <Grid xs = {5} md = {5}>
                <IconButton sx = {{color:'white'}} onClick = {()=> {setOpen(current => !current)}}>
                  <ExpandLessIcon/>
                </IconButton>
              </Grid>

              <Grid xs = {5} md = {5}>
                <Typography variant = 'h6' color={'white'} alignItems = 'center'> <b>Timer</b></Typography>
              </Grid>
            </Grid>
        {!startTimer &&
          <Stack  direction = "row" spacing={1} sx = {{mt: '10px', mb: '10px', ml: '10px',mr: '10px'}}>
            <TextField  label="Hours" InputLabelProps={{shrink: true, }} variant="filled" sx = {{backgroundColor: 'white', borderRadius: 1, "& label.Mui-focused": {
            color: "#1b4332"},   "& .MuiFilledInput-underline:after": {borderBottomColor: "#1b4332"} }} value={hrsInput} onChange={(e) => setHrsInput(e.target.value)}/>
            <TextField  label="Mins"  InputLabelProps={{shrink: true,}} variant="filled" sx = {{backgroundColor: 'white', borderRadius: 1, "& label.Mui-focused": {
            color: "#1b4332"},   "& .MuiFilledInput-underline:after": {borderBottomColor: "#1b4332"}}} value={minsInput} onChange={(e) => setMinsInput(e.target.value)}
            />
          
            <IconButton onClick = {startTimerClick} sx = {{color: 'white'}} >
              <PlayArrowIcon/>
            </IconButton>
          </Stack>
       
        }
      
        {startTimer && (

            <Stack  direction= "row" justifyContent='center' alignItems= "center" spacing = {1}sx = {{mt: '10px', mb: '10px'}}>
                <CountDownTimer hours = {parseInt(hrsInput)} minutes = {parseInt(minsInput)} seconds = {0}></CountDownTimer>

                <Button variant="outlined" onClick = {startTimerClick} sx = {{ height: '100%' ,color: 'white' ,":hover": {borderColor: 'white' }, borderColor: 'white'}}>
                  <StopIcon/>
                </Button>
    
        
            </Stack>

        )}


      </Box>
      )}
    </Box>
  )

}
export default TimerTool; 