import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ReactPlayer from "react-player";
import { useRef, useState } from "react";

import PlayerControls from "../playerControls";

// type Props = {
//   url: string;
// };

const JazzPlayer = () => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(0.5);


  const playerRef = useRef<ReactPlayer | null>(null);

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleVolumeChange = (newVolume: number) => {
    setVolume(newVolume);
  };

  const toggleMute = () => {
    setMuted((prevMuted) => !prevMuted);
  };

  return (
    <Box>

      <Stack direction = 'column' spacing = {-1}  justifyContent="center" alignItems="center">
        <Typography color= 'white'>
          Jazz
        </Typography>
      
        <ReactPlayer
          ref={playerRef}
          url='https://youtu.be/Sllp_eTV7Ks'
          playing={playing}
          volume={volume}
          muted={muted}
          onPlay={handlePlay}
          onPause={handlePause}
          height={'10px'}
          width = {'1px'}
        />
        <PlayerControls
          playerRef={playerRef}
          playing={playing}
          volume={volume}
          muted={muted}
          // event handler props
          toggleMute={toggleMute}
          handlePlay={handlePlay}
          handlePause={handlePause}
          handleVolumeChange={handleVolumeChange}
        />
      </Stack>
    </Box>
  );
};

export default JazzPlayer; 