import Box from '@mui/material/Box';
import NavBar from './components/navBar';
import HomeAmbiance from './pages/ambianceOptions/homeAmbiance';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './layout';
import Home from './pages/home';
import AmbianceLayout from './components/ambianceLayout';
import CreateLayout from './pages/ambianceOptions/create';
function App() {
  return (
    
    <Box>

      <Routes>
        <Route index element = {<Home/>}/> 
        <Route path = "/" element = {<Layout/>}>
          

          <Route path = "/home-ambiance" element = {<HomeAmbiance/>}/>
          <Route path = "/dark-academia" element = {<AmbianceLayout  url = {"https://www.youtube.com/embed/UZ_ddEAnPtQ?si=9bQFlPUixZZra3I2"} lightColor={'#3e6181'} mediumColor = {'#314d65'} darkColor={'#1a2328'} gradient='linear-gradient(#314d65, #1a2328)'
/>}/>
          <Route path = "/lofi-hip-hop" element = {<AmbianceLayout  url = {"https://www.youtube.com/embed/jfKfPfyJRdk?si=SN0AmfJGHalerKb2"} lightColor={'#d8f3dc'} mediumColor = {'#2d6a4f'} darkColor={'#1b4332'} gradient='linear-gradient(#007759, #003125)'
/>}/>
          <Route path = "/coffee-shop" element = {<AmbianceLayout  url = {"https://www.youtube.com/embed/MYPVQccHhAQ?si=hF2s96DJZtqyP_Ay"} lightColor={'#DFA878'} mediumColor = {'#BA704F'} darkColor={'#6C3428'} gradient='linear-gradient(#BA704F, #6C3428)'
/>}/>
          <Route path = "create-your-own" element = {<CreateLayout   lightColor={'#00a1ff'} mediumColor = {'#00aeef'} darkColor={'#2d388a'} gradient='linear-gradient(#00aeef, #2d388a)'
/>}/>
        </Route>
      </Routes>

    </Box>
  );
}

export default App;
