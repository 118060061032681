import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { List } from '@mui/material';
import Paper from '@mui/material/Paper';
import RemoveIcon from '@mui/icons-material/Remove';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useState } from 'react';
type Props = {
  lightColor: string;
  mediumColor: string; 
  darkColor: string; 
}
const ToDoList = ({lightColor, mediumColor, darkColor}: Props) => {
  const [addTask, setAddTask] = useState(false)
  const [input, setInput] = useState(""); //text field where user enters new task 
  const [task, setTask] = useState("")
  const [list, setList] = useState<string[]>([]);
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    const newList = [...list, input];
    setList(newList)
    setInput("")
  } 



  function ListItem(props: {
    value:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
  }) {
    return <li>{props.value}</li>;
  }
  return (
    <div>
   
      <Box sx = {{backgroundColor: 'rgba(0,0,0,.1)' ,boxShadow: 5, width: '100%', borderRadius: 2, mb: '10px', ml: '30px'}}>

        {!open && (
        <Box>
          <Grid container direction = 'row'> 
            <Grid xs = {4} md = {4}>
              <IconButton sx = {{color:'white'}} onClick = {()=> {setOpen(current => !current)}}>
                <ExpandMoreIcon/>
              </IconButton>
            </Grid>

            <Grid xs = {5} md = {5}>
              <Typography variant = 'h6' color={'white'} alignItems = 'center'> <b>To-Do List</b></Typography>
            </Grid>
          </Grid>
        </Box>
             )}


        {open && ( 
          <div>
          <Grid container direction = 'row'> 

            <Grid xs = {4} md = {4}>
              <IconButton sx = {{color:'white'}} onClick = {()=> {setOpen(current => !current)}}>
                <ExpandLessIcon/>
              </IconButton>
            </Grid>

            <Grid xs = {5} md = {5}>
              <Typography variant = 'h6' color={'white'} alignItems = 'center'> <b>To-Do List</b></Typography>
            </Grid>
        </Grid>

          

        
        <Stack direction="column" justifyContent="center" alignItems="center" >
          <List>
            {list.map((lists) => (
              <Box sx = {{mb: '10px'}}>
              <Paper elevation = {3} >
                <Stack direction = "row" justifyContent="baseline" alignItems="center" >
                  <Checkbox/>
                  <Box sx ={{mr: '10px'}}>
                    <ListItem key={lists.toString()} value={lists}/>
                  </Box>
                </Stack>
              </Paper>
              </Box>
              ))}
          </List>


        {!addTask &&(
           <Button  variant = 'contained' sx ={{backgroundColor: mediumColor ,color: 'white',":hover": {
            bgcolor: darkColor
          } ,mb: '10px'}} onClick = {() => setAddTask(true)}>
              <AddIcon/> 
            <Typography color={'white'}> Add a task </Typography>
         </Button>
        )}
       

        {addTask && (
          <Stack direction = "row" spacing = {2} sx = {{mb: '10px'}}>
            <TextField id ="filled-basic"  sx = {{backgroundColor: 'white',}}label = "Enter Task" variant='filled' value = {input} onChange={(e) => setInput(e.target.value)}></TextField>
            <Button variant = 'contained'sx ={{backgroundColor : mediumColor,":hover": {
            bgcolor: darkColor
          }}} onClick = {handleClick}> 
              <AddIcon/>
            </Button>
          </Stack>
        )}
        {/* <Checkbox /> */}
        </Stack>
        </div>
        )}
      </Box>

    </div>
  )
}
export default ToDoList; 